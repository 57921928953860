import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'default-passive-events'
import VueClipboard from 'vue-clipboard2'
import UploadComponent from 'vue-upload-component'
import SlideVerify from 'vue-monoplasty-slide-verify';
import store from './store'
import axios from 'axios'
import App from './App.vue'
import router from './router'
// 挂载全局
Vue.prototype.$axios = axios



Vue.use(ElementUI)

Vue.use(VueClipboard)

Vue.component('file-upload', UploadComponent)

// 滑块验证

Vue.use(SlideVerify);

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
