import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Router from 'vue-router'
Vue.use(VueRouter)

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { title: '天天快办' },
      component: HomeView,
      meta: { isAuth: true, title: '主页' },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),

    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import(/* webpackChunkName: "about" */ '../views/apply.vue')
    },
    {
      path: '/apply_msg',
      name: 'apply_msg',
      component: () => import(/* webpackChunkName: "about" */ '../views/apply_msg.vue')
    },
    {
      path: '/addressed',
      name: 'addressed',
      component: () => import(/* webpackChunkName: "about" */ '../views/addressed.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "about" */ '../views/users.vue')
    },
    {
      path: '/stencil',
      name: 'stencil',
      component: () => import(/* webpackChunkName: "about" */ '../views/stencil.vue')
    },
    {
      path: '/order',
      name: 'order',
      component: () => import(/* webpackChunkName: "about" */ '../views/order.vue')
    },
    {
      path: '/topay',
      name: 'topay',
      component: () => import(/* webpackChunkName: "about" */ '../views/topay.vue')
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
    },
    {
      path: '/order_pic',
      name: 'order_pic',
      component: () => import(/* webpackChunkName: "about" */ '../views/order_pic.vue')
    },
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import(/* webpackChunkName: "about" */ '../views/registration.vue')
    },
    {
      path: '/mymsg',
      name: 'mymsg',
      component: () => import(/* webpackChunkName: "about" */ '../views/mymsg.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    }
  ],
  mode: 'history',
  base: process.env.BASE_URL,
  // routes

})

//全局路由守卫----初始化的时候被调用，每次路由切换之前被调用
router.beforeEach((to, from, next) => {
  // var _this = this
  let userids = sessionStorage.getItem("userid")
  if (to.path === '/registration' || to.path === '/Privacy') { // 判断是否为登录页面或其他需要放行的页面
    next()
  } else {
    if (userids) {
      next()
    } else {
      if (to.path === '/login') {
        next()
      } else {
        next('/login');
      }
    }
  }
})



export default router
