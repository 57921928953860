<template>
  <div class="home">
    <div class="top_box">
      <!-- logo -->
      <div class="log_top">
        <div style="height:110px;" class="img_rt">
          <img src="../../images/logo1.png" alt="" style="width:176px;height:62px;">
        </div>
        <div class="info_r">
          <div style="height:110px;" class="img_rt">
            <img src="../../images/heade.png" alt="" style="width:60px;height:60px;">
          </div>
          <div class="inforbar_box">
            <ul>
              <!-- <li class="in_item f-center"><a href="/mymsg">我的信息</a></li> -->
              <li class="in_item f-center" @click="gochart"><a href="">联系我们</a></li>
              <li class="in_item f-center"><a href="" @click="logout">退出登录</a></li>
            </ul>
          </div>
        </div>

      </div>
      <!-- 信息栏 -->
      <div class="infobar">

      </div>
    </div>
    <div class="content_box">
      <div class="con_items">
        <div class="con_items_top">
          <div class="con_items_top_box f-center" @click="ToApply">
            <div class="item_img f-center"><img src="../../images/item1.png" alt=""></div>
            <div class="f-center item_tot">
              <p>申请开票</p>
            </div>
            <div class="f-center item_cos_a ">
              <p>实名，签合同，正式开票</p>
            </div>
          </div>
          <div class="con_items_top_box f-center" @click="Toapply_msg" style="margin:0 30px">
            <div class="item_img f-center"><img src="../../images/item3.png" alt=""></div>
            <div class="f-center item_tot">
              <p>发票抬头</p>
            </div>
            <div class="f-center item_cos">
              <p>抬头新增与详情</p>
            </div>
          </div>
          <div class="con_items_top_box f-center" @click="Toorder">
            <div class="item_img f-center"><img src="../../images/item2.png" alt=""></div>
            <div class="f-center item_tot">
              <p>我的订单</p>
            </div>
            <div class="f-center item_cos">
              <p>发票订单在这里</p>
            </div>
          </div>
        </div>
      </div>
      <div class="con_items_btm">
        <div class="con_items_btm_item" @click="Tostencil">
          <div class="btm_img"><img src="../../images/item4.png" alt=""></div>
          <div class="btm_text f-center">
            <p class="f-center item_tot">发票样例</p>
            <p class="f-center item_cos">发票订单在这里</p>
          </div>
        </div>
        <div class="con_items_btm_item" @click="Toaddress" style="margin:0 30px">
          <div class="btm_img"><img src="../../images/add.png" alt=""></div>
          <div class="btm_text f-center">
            <p class="f-center item_tot">邮寄地址</p>
            <p class="f-center item_cos">发票邮寄地址</p>
          </div>
        </div>
        <div class="con_items_btm_item" @click="touser">
          <div class="btm_img"><img src="../../images/item5.png" alt=""></div>
          <div class="btm_text f-center">
            <p class="item_tot f-center">开票人信息</p>
            <p class="item_cos f-center">我的信息在这里</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  methods: {
    // 跳转到申请开票页面
    ToApply() {
      this.$router.push({ path: '/apply', query: { ids: 0 } })
    },

    //退出登录
    logout() {
      window.sessionStorage.removeItem('userid');
      this.$router.push({ name: 'login', })
    },

    //跳转抬头
    Toapply_msg() {
      this.$router.push({ name: 'apply_msg', query: { ids: 1 } })
    },

    //跳转订单
    Toorder() {
      this.$router.push({ name: 'order', query: { ids: 2 } })
    },

    //发票样例
    Tostencil() {
      this.$router.push({ name: 'stencil', query: { ids: 3 } })
    },

    // 邮寄地址
    Toaddress() {
      this.$router.push({ name: 'addressed', query: { ids: 4 } })
    },

    //开票人信息
    touser() {
      this.$router.push({ name: 'users', query: { ids: 5 } })
    },

    //联系我们
    gochart(){
      this.$router.push({name:'mymsg'})
    }

  }
}
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}

.info_r {
  position: relative;
  display: inline-block;
}

.info_r:hover .inforbar_box {
  display: block;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  white-space: 1px;
}

ul li {
  list-style: none;
}

.f-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home {
  background-image: url(../../images/in_bgs.png);
  background-repeat: no-repeat;
  background-size: 100% 410px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.top_box {
  width: 68%;
  height:33vh;
}

.log_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 16px;
}

.infobar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inforbar_box {
  width: 70px;
  background-color: #fff;
  font-size: 16px;
  color: #555;
  border-radius: 10px;
  padding: 16px 24px;
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
}

.in_item {
  line-height: 36px;
  font-weight: 500;
  font-family: "黑体";
}

.content_box {
  width: 100%;
  height: calc(100vh - 33vh);
  background-color: #F6F7FB;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.con_items {
  /* width:68%; */
  padding: 20px 0 0 0;
  width: 68%;
}

.con_items_top_box {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px #ccc;
  padding: 80px 20px 80px 20px;
  width: 26%;
  height: 210px;
  cursor: pointer;
}

.con_items_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_img {
  margin-bottom: 40px;
  width: 140px;
  height: 140px;
}

.item_tot {
  font-size: 21px;
  font-weight: 600;
  color: #333;
  white-space: 2px;
  font-family: "黑体";
  margin-bottom: 8px;
  width: 100%;
}

.item_cos {
  font-size: 16px;
  /* font-weight: 600; */
  color: #777;
  white-space: 2px;
  font-family: "微软雅黑";
  margin-bottom: 12px;
  width: 100%;
}

.item_cos_a {
  font-size: 16px;
  font-weight: 600;
  color: #666;
  white-space: 2px;
  font-family: "黑体";
  margin-bottom: 12px;
  width: 100%;
}

.imgs {
  width: 123px;
  height: 104px;
}

.con_items_btm {
  width: 68%;
  /* width: 100%; */
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.con_items_btm_item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px #ccc;
  padding: 20px;
  width: 26%;
  cursor: pointer;
}

.btm_text {
  flex-wrap: wrap;
}

.btm_text {
  flex-wrap: wrap;
}

.img_rt {
  display: flex;
  align-items: center;
  justify-content: center;
}</style>
