var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top_box"},[_c('div',{staticClass:"log_top"},[_vm._m(0),_c('div',{staticClass:"info_r"},[_vm._m(1),_c('div',{staticClass:"inforbar_box"},[_c('ul',[_c('li',{staticClass:"in_item f-center",on:{"click":_vm.gochart}},[_c('a',{attrs:{"href":""}},[_vm._v("联系我们")])]),_c('li',{staticClass:"in_item f-center"},[_c('a',{attrs:{"href":""},on:{"click":_vm.logout}},[_vm._v("退出登录")])])])])])]),_c('div',{staticClass:"infobar"})]),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"con_items"},[_c('div',{staticClass:"con_items_top"},[_c('div',{staticClass:"con_items_top_box f-center",on:{"click":_vm.ToApply}},[_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"con_items_top_box f-center",staticStyle:{"margin":"0 30px"},on:{"click":_vm.Toapply_msg}},[_vm._m(5),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"con_items_top_box f-center",on:{"click":_vm.Toorder}},[_vm._m(8),_vm._m(9),_vm._m(10)])])]),_c('div',{staticClass:"con_items_btm"},[_c('div',{staticClass:"con_items_btm_item",on:{"click":_vm.Tostencil}},[_vm._m(11),_vm._m(12)]),_c('div',{staticClass:"con_items_btm_item",staticStyle:{"margin":"0 30px"},on:{"click":_vm.Toaddress}},[_vm._m(13),_vm._m(14)]),_c('div',{staticClass:"con_items_btm_item",on:{"click":_vm.touser}},[_vm._m(15),_vm._m(16)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_rt",staticStyle:{"height":"110px"}},[_c('img',{staticStyle:{"width":"176px","height":"62px"},attrs:{"src":require("../../images/logo1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_rt",staticStyle:{"height":"110px"}},[_c('img',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":require("../../images/heade.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_img f-center"},[_c('img',{attrs:{"src":require("../../images/item1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_tot"},[_c('p',[_vm._v("申请开票")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_cos_a"},[_c('p',[_vm._v("实名，签合同，正式开票")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_img f-center"},[_c('img',{attrs:{"src":require("../../images/item3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_tot"},[_c('p',[_vm._v("发票抬头")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_cos"},[_c('p',[_vm._v("抬头新增与详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_img f-center"},[_c('img',{attrs:{"src":require("../../images/item2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_tot"},[_c('p',[_vm._v("我的订单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-center item_cos"},[_c('p',[_vm._v("发票订单在这里")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_img"},[_c('img',{attrs:{"src":require("../../images/item4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_text f-center"},[_c('p',{staticClass:"f-center item_tot"},[_vm._v("发票样例")]),_c('p',{staticClass:"f-center item_cos"},[_vm._v("发票订单在这里")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_img"},[_c('img',{attrs:{"src":require("../../images/add.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_text f-center"},[_c('p',{staticClass:"f-center item_tot"},[_vm._v("邮寄地址")]),_c('p',{staticClass:"f-center item_cos"},[_vm._v("发票邮寄地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_img"},[_c('img',{attrs:{"src":require("../../images/item5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btm_text f-center"},[_c('p',{staticClass:"item_tot f-center"},[_vm._v("开票人信息")]),_c('p',{staticClass:"item_cos f-center"},[_vm._v("我的信息在这里")])])
}]

export { render, staticRenderFns }